import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {BasePopover} from '../../../classes/BasePopover';
import {PopoverRef} from '../../../popovers/popover/popover-ref';
import {HomestateService} from '../../../services/homestate.service';
import {InstantaneousService} from '../../../services/instantaneous.service';
import {map, mergeMap, switchMap} from 'rxjs/operators';
import {MetaResponse} from '../../../shared/interfaces/meta-response';
import {Observable, of} from 'rxjs';
import * as moment from 'moment/moment';
import {HttpErrorResponse} from '@angular/common/http';
import {
    RealTimeAlertChartComponent
} from '../../../charts/real-time-alert-chart/real-time-alert-chart.component';
import {ConfigurationService} from '../../../services/configuration.service';
import {ApplicationService} from '../../../services/application.service';
import {AnimationOptions} from 'ngx-lottie';

enum AlarmViewMode {
    OVERVIEW = 'Overview',
    MANAGE = 'Alarme verwalten'
}

@Component({
    selector: 'app-real-time-alert-details',
    templateUrl: './real-time-alert-details.component.html',
    styleUrls: ['./real-time-alert-details.component.scss'],
    viewProviders: [ApiService]
})
export class RealTimeAlertDetailsComponent extends BasePopover implements OnInit, OnDestroy, AfterViewInit {
    showEmptyState = true;
    noAlarmState = true;
    infoVisible = false;
    readonly alarmViewMode = AlarmViewMode;
    currentMode = AlarmViewMode.OVERVIEW;
    private alertChart: RealTimeAlertChartComponent = null;
    private cachedDiagramData = null;
    feedinDiagramSeriesColor = '#39393a';
    chartInitialized = false;
    private lastFetchedDataset = null;
    alarmsFromConfig = null;
    alarmsData = null;
    currentDataOffset = 0;
    maxDataOffset = 6;
    powerValue = null;
    firstAbovePowerValue = null;
    firstBelowPowerValue = null;
    aboveIcon = null;
    belowIcon = null;


    constructor(protected popoverRef: PopoverRef,
                private instantaneous: InstantaneousService,
                private homestate: HomestateService,
                private application: ApplicationService,
                private configuration: ConfigurationService) {
        super(popoverRef);
    }

    lottieConfig: AnimationOptions = {
        path: 'assets/anim/empty-states/consumption-alert.json',
        renderer: 'svg',
        autoplay: true,
        loop: true,
        name: 'Echtzeitalarm '
    };

    ngOnInit() {
        this.getAlarmsFromDashboardConfig().pipe(
            switchMap(() => this.getAlarmsData())
        ).subscribe({
            next: (mappedThresholds) => {
                this.alarmsData = mappedThresholds;
                if (this.chartInitialized) {
                    this.addHorizontalLines();
                }
                if (mappedThresholds.length) {
                    this.noAlarmState = false;
                }
            },
            error: (err) => {
                console.error('Error fetching alarms:', err);
            }
        });
    }


    ngAfterViewInit() {
        this.getSevenDayHistoricalData();
        this.getAlarmsData();
        if (this.alarmsData.length) {
            this.noAlarmState = false;
        }
    }

    toggleAlarms(isEnabled: boolean, name: string): void {
        const alarmToUpdate = this.alarmsData.find((alarm) => alarm.name === name);

        if (alarmToUpdate) {
            alarmToUpdate.enabled = isEnabled;

            const updatedThreshold = {
                [alarmToUpdate.key]: {
                    enabled: !alarmToUpdate.enabled,
                    threshold_value: alarmToUpdate.threshold_value,
                    alert_type: alarmToUpdate.alert_type,
                    cooldown: alarmToUpdate.cooldown,
                    aggregation_method: alarmToUpdate.aggregation_method,
                    aggregation_sample_count: alarmToUpdate.aggregation_sample_count,
                    aggregation_sample_interval: alarmToUpdate.aggregation_sample_interval,
                    last_updated: alarmToUpdate.last_updated
                }
            };

            const payload = {
                mains: updatedThreshold
            };

            this.homestate.updatePowerThresholds(payload).subscribe({
                next: (response) => {
                    console.log('Successfully updated power thresholds:', response);
                },
                error: (err) => {
                    console.error('Error updating power thresholds:', err);
                }
            });
        } else {
            console.warn(`Alarm with name ${name} not found`);
        }
    }



    setMode(newMode: AlarmViewMode) {
        if (newMode === this.currentMode) {
            return;
        }
        this.currentMode = newMode;
    }


    onChartLoaded(chart: RealTimeAlertChartComponent): void {
        this.alertChart = chart;
        this.chartInitialized = true;
        this.updateDiagramFromCachedData();
        this.alertChart.deactivateXAxisLabelLimitation();
        if (this.alarmsData) {
            this.addHorizontalLines();
        }
    }

    addHorizontalLines() {
        this.alarmsData.forEach((data) => {
            if (data.enabled) {
                switch (data.type) {
                    case 'custom':
                        this.alertChart.addHorizontalLine(data.threshold_value, '#39393a', '/assets/img/graphics/real-time/service.png');
                        break;
                    case 'feed_in':
                        this.alertChart.addHorizontalLine(data.threshold_value, '#39393a', '/assets/img/graphics/real-time/sun-inverted.png');
                        break;
                    case 'high':
                        this.alertChart.addHorizontalLine(data.threshold_value, '#b00402', '/assets/img/graphics/real-time/high-consumption.png');
                        break;
                    case'medium':
                        this.alertChart.addHorizontalLine(data.threshold_value, '#39393a', '/assets/img/graphics/real-time/mid-consumption.png');
                        break;
                    case 'low':
                        this.alertChart.addHorizontalLine(data.threshold_value, '#1ea2b1', '/assets/img/graphics/real-time/low-consumption.png');
                        break;
                    default:
                        this.alertChart.addHorizontalLine(data.threshold_value, '#39393a', '');
                }
            }
        });
    }

    determineMeterIcons(type: string): string {
        switch (type) {
            case 'custom':
                return '/assets/img/graphics/real-time/service-small.png';
            case 'feed_in':
                return '/assets/img/graphics/real-time/sun-gray.png';
            case 'high':
                return '/assets/img/graphics/real-time/high-consumption-small.png';
            case'medium':
                return '/assets/img/graphics/real-time/mid-consumption-small.png';
            case 'low':
                return '/assets/img/graphics/real-time/low-consumption-small.png';
            default:
                return '';
        }
    }

    determineMeterValue(): string {
        if (!this.powerValue?.power || !this.alarmsData){
            return null;
        }
        const power = this.powerValue?.power;

        for (const alarm of Object.values(this.alarmsData)) {
        // @ts-ignore
            const { threshold_value: val, type } = alarm;
            if (val <= power) {
                this.firstBelowPowerValue = val;
                this.belowIcon = this.determineMeterIcons(type);

            }
            if (val >= power) {
                this.firstAbovePowerValue = val;
                this.aboveIcon = this.determineMeterIcons(type);
            }
        }
        const min = this.firstBelowPowerValue ?? power;
        const max = this.firstAbovePowerValue ?? power;

        const span = max - min;
        const percentage = (power - min) * 100.0 / span;


        if (percentage < 12.5) {
            return '/assets/images/meter_zero.png';
        } else if (percentage >= 12.5 && percentage < 37.5) {
            return '/assets/images/meter_quarter_left.png';
        } else if (percentage >= 37.5 && percentage < 62.5) {
            return '/assets/images/meter_mid.png';
        } else if (percentage >= 62.5 && percentage < 87.5) {
            return '/assets/images/meter_quarter_right.png';
        } else if (percentage >= 87.5) {
            return '/assets/images/meter_full.png';
        }
    }

    private getAlarmsFromDashboardConfig(): Observable<void> {
        if (this.application.isDemoMode()) {
            return of();
        }

        return this.configuration.requestAlarmsDataFromConfig().pipe(
            map((storedData) => {
                if (storedData.status === 'ok') {
                    this.alarmsFromConfig = storedData?.data?.power_thresholds;
                }
            })
        );
    }

    private getAlarmsData(): Observable<any[]> {
        return this.homestate.fetchPowerThresholds().pipe(
            map(({data}) => {
                return Object.entries(data.mains).map(([key, threshold]) => {
                    const configData = this.alarmsFromConfig[key];
                    return {
                        // @ts-ignore
                        ...threshold,
                        name: configData?.name || 'Unknown',
                        type: configData?.type || 'unknown',
                        key,
                    };
                });
            })
        );
    }

    getIconForAlarmType(alarmType: string, isTitle: boolean = false): string {
        switch (alarmType) {
            case 'custom':
                return isTitle ? '/assets/img/graphics/real-time/more-alarm-gray.png' :
                    '/assets/img/graphics/real-time/service-alarm.png';
            case 'feed_in':
                return isTitle ? '/assets/img/graphics/real-time/more-alarm-gray.png' :
                    '/assets/img/graphics/real-time/sun-gray.png';
            case 'high':
                return isTitle ? '/assets/img/graphics/real-time/more-alarm-red.png' :
                    '/assets/img/graphics/real-time/high-alarm.png';
            case'medium':
                return isTitle ? '/assets/img/graphics/real-time/more-alarm-gray.png' :
                    '/assets/img/graphics/real-time/mid-alarm.png';
            case 'low':
                return isTitle ? '/assets/img/graphics/real-time/more-alarm-blue.png' :
                    '/assets/img/graphics/real-time/low-alarm.png';
            default:
                return isTitle ? '/assets/img/graphics/real-time/service-small.png' :
                    '/assets/img/graphics/real-time/service-alarm.png';
        }
    }


    stepForward(): void {
        --this.currentDataOffset;
        this.updateDiagramFromCachedData();
    }


    stepBack(): void {
        ++this.currentDataOffset;
        this.updateDiagramFromCachedData();
    }

    resetHistory(): void {
        this.currentDataOffset = 0;
        this.updateDiagramFromCachedData();
    }


    private getSevenDayHistoricalData(): void {
        this.instantaneous.onConsumptionAlertDataUpdate.pipe(
            mergeMap((results: MetaResponse) => {
                if (results) {
                    if (results.status === 'error') {
                        this.handleInstantaneousErrors(results.data);
                    } else {
                        return of(results.data.results);
                    }
                }
                return of(null);
            })).subscribe({
            next: result => {
                if (!result) {
                    return;
                }
                this.cachedDiagramData = result;
                this.powerValue = result[result.length - 1];
                this.updateDiagramFromCachedData();
                this.showEmptyState = false;
            },
            error: error => {
                if (this.chartInitialized) {
                    this.alertChart.showLoadingState();
                    console.log('Error: ', error);
                }
            },
        });
        this.instantaneous.startConsumptionAlertValueLiveUpdate(this.maxDataOffset);
    }

    private extractSeriesDataFromCache(): void {
        const start = moment().subtract(this.currentDataOffset + 1, 'days').toDate();
        const end = moment().subtract(this.currentDataOffset, 'days').toDate();
        this.lastFetchedDataset = this.cachedDiagramData.filter(el => {
            const ts = moment(el.timestamp).toDate();
            return ts >= start && ts <= end;
        });
    }


    private updateDiagramFromCachedData(): void {
        try {
            this.extractSeriesDataFromCache();
            if (this.chartInitialized) {
                this.updateChart();
            }

        } catch (e) {
            if (this.chartInitialized) {
                this.alertChart.showLoadingState();
            }
        }
    }


    private updateChart(): void {
        this.alertChart.showLoadingState(false);
        const fedEnergy = [];
        for (const el of this.lastFetchedDataset) {
            const element = {
                power: el.power < 0 ? Math.abs(el.power) : null,
                timestamp: el.timestamp
            };
            if (element.power) {
                fedEnergy.push(element);
            }
        }
        const consumedEnergy = [];
        for (const el of this.lastFetchedDataset) {
            const element = {
                power: el.power >= 0 ? el.power : null,
                timestamp: el.timestamp
            };
            consumedEnergy.push(element);
        }
        if (consumedEnergy) {
            this.alertChart.addNewSeries(
                consumedEnergy,
                'power',
                1,
            );
        }
        if (fedEnergy) {
            this.alertChart.addNewSeries(
                fedEnergy,
                'power',
                0,
                {zindex: 2, isTileChart: true, color: this.feedinDiagramSeriesColor}
            );
        }

        const time = moment(this.lastFetchedDataset.first().timestamp).endOf('day');
        this.alertChart.insertVerticalLine(time.toDate());
    }


    private handleInstantaneousErrors(error: HttpErrorResponse): void {
        if (error.error.error.code === 290) {
            this.showEmptyState = true;
        }
        this.chartInitialized = false;
    }
}

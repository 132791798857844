import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base-service';
import {ApiService} from './api.service';
import {UserService} from './user.service';
import {constants} from '../shared/constants/constants';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class FirmwareUpdateService extends BaseService {

    onUpdateStateReceived = new BehaviorSubject<number>(0);

    constructor(protected http: HttpClient,
                protected auth: ApiService,
                protected user: UserService) {
        super(http, auth, user);
    }
}

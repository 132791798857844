<div class="inflate container">
    <ng-container *ngIf="householdDeviceCategoriesData$ | async as householdDeviceCategoriesData">

        <ng-container *ngIf="householdDeviceCategoriesData.viewState === ViewState.LOADING">
            <div class="inflate center-contents">
                {{ LABELS.stateLoading }}
            </div>
        </ng-container>

        <ng-container *ngIf="householdDeviceCategoriesData.viewState === ViewState.ERROR">
            <div class="inflate empty-state-container">
                <div class="empty-state-wrapper">
                    <div class="empty-state-img"></div>
                    <div class="empty-state-label">
                        {{ LABELS.stateError }}
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="householdDeviceCategoriesData.viewState === ViewState.EMPTY">
            <div class="inflate empty-state-container">
                <div class="empty-state-wrapper">
                    <div class="empty-state-img"></div>
                    <div class="empty-state-label">
                        {{ LABELS.stateEmptyDetail }}
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="householdDeviceCategoriesData.viewState === ViewState.NO_DATA">
            <div class="inflate empty-state-container">
                <div class="empty-state-wrapper">
                    <div class="no-data-img"></div>
                    <div class="empty-state-label">
                        {{LABELS.noDataState}}
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="householdDeviceCategoriesData.viewState === ViewState.SUCCESS">
            <div class="pill-container">
                <div class="stat-pill consumption-me">
                    <div class="eon-medium">{{ LABELS.devicesMyConsumption }}</div>
                    <div class="value eon-bold">
                        {{ householdDeviceCategoriesData.totalConsumptionMe | number: '1.0-0':'de-DE' }}
                        {{ LABELS.unitKwh }}
                    </div>
                </div>

                <div class="stat-pill consumption-other">
                    <div class="eon-medium">{{ LABELS.devicesComparableHouseholds }}</div>
                    <div class="value eon-bold">
                        {{ householdDeviceCategoriesData.totalConsumptionOthers | number: '1.0-0':'de-DE' }}
                        {{ LABELS.unitKwh }}
                    </div>
                </div>
            </div>

            <div class="devices-container scroll-container-vertical household-comparison">
                <h2>{{ LABELS.devicesBreakdownTitle }}</h2>
                <div class="appliance-diagram-container" *ngFor="let item of householdDeviceCategoriesData.deviceCategories">
                    <h3>{{ translateApplianceLowercase(item.name) }}</h3>
                    <div class="device-diagram">
                        <div class="diagram-bar">
                            <div class="value-container consumption-me font-bold">
                                <div class="value">
                                    {{ item.consumptionMe | number: '1.0-0':'de-DE' }}
                                    {{ LABELS.unitKwh }}
                                </div>
                            </div>
                            <div class="bar-wrapper">
                                <div class="bar consumption-me" [style.flex-basis]="item.consumptionRatioMe + '%'"></div>
                            </div>
                        </div>
                        <div class="diagram-bar">
                            <div class="value-container consumption-other font-bold">
                                <div class="value">
                                    {{ item.consumptionOthers | number: '1.0-0':'de-DE' }}
                                    {{ LABELS.unitKwh }}
                                </div>
                            </div>
                            <div class="bar-wrapper">
                                <div class="bar consumption-other" [style.flex-basis]="item.consumptionRatioOthers + '%'"></div>
                            </div>
                        </div>
                        <div class="icon"
                             [style.mask-image]="getApplianceIconUrl(item.name)"
                             [style.-webkit-mask-image]="getApplianceIconUrl(item.name)">
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

    </ng-container>
</div>

import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {BehaviorSubject, Observable, of, Subject, Subscription, timer} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {constants} from '../shared/constants/constants';
import {BaseService} from './base-service';
import {UserService} from './user.service';
import {ApplicationService} from './application.service';
import {StorageAttributes} from '../shared/constants/storage-attributes.constants';

@Injectable({
    providedIn: 'root'
})
export class HomestateService extends BaseService {
    onHomestateInfo = new BehaviorSubject<any>(null);
    onHomestateStatus = new Subject<any>();

    private bundleTimerSub: Subscription = null;
    private timerSub: Subscription = null;

    private updateRate = 10000;

    constructor(protected http: HttpClient,
                protected auth: ApiService,
                protected user: UserService,
                private application: ApplicationService) {
        super(http, auth, user);
    }


    destroy(): void {
        super.destroy();
        if (this.timerSub) {
            this.timerSub.unsubscribe();
            delete this.timerSub;
        }
        this.onHomestateInfo.next(null);
        this.onHomestateStatus.next(null);
    }


    startLiveUpdateForBundledInfo(): void {
        if (this.bundleTimerSub) {
            return;
        }
        this.bundleTimerSub = timer(0, this.updateRate).pipe(
            mergeMap(() => this.getBundledInfo()),
            // tap(info => console.log('bundled info here', info))
        ).pipe(
            catchError((error: any) => this.handleError(error))
        ).subscribe(
            (res) => {
                if (res) {
                    this.onHomestateInfo.next(res);
                }
            }
        );
    }


    startLiveUpdate(): void {
        if (this.timerSub) {
            return;
        }
        this.timerSub = timer(0, this.updateRate).pipe(
            mergeMap((cycle) => this.getStatus())
        ).pipe(
            catchError((error: any) => this.handleError(error))
        ).subscribe(
            (res) => {
                if (res) {
                    this.onHomestateStatus.next(res);
                }
            }
        );
    }


    getStatus(): Observable<any> {
        // todo make this nice
        if (!this.isMeterConnected()) {
            return of(null);
        }

        const url = this.API_BASE_URL + constants.api.routes.homeState.current;
        return this.http.get(url).pipe(
            map((res: { status: string, data: any }) => {
                return this.mapDefault(res);
            }),
            catchError((error: any) => this.handleError(error))
        );
    }


    private getBundledInfo(): Observable<any> {
        if (!this.isMeterConnected()) {
            return of(null);
        }
        return this.requestHomestateStatus().pipe(
            // tap(status => console.log('status here', status)),
            mergeMap(status =>
                this.requestHomestateConfig().pipe(
                    // tap(config => console.log('config here', config)),
                    mergeMap(config => of({config, status}))
                )
            )
        );
    }


    private requestHomestateStatus(): Observable<any> {
        let url = this.API_BASE_URL + constants.api.routes.homeState.current;
        if (this.application.isDemoMode()) {
            url = `assets/data/demo/${constants.demo.files.homestateCurrent}.json`;
        }
        return this.http.get(url).pipe(
            mergeMap((res: { status: string, data: any }) => of(this.mapDefault(res))),
            catchError((error: any) => this.handleError(error))
        );
    }


    private requestHomestateConfig(): Observable<any> {
        let url = this.API_BASE_URL + constants.api.routes.homeState.config;
        if (this.application.isDemoMode()) {
            url = `assets/data/demo/${constants.demo.files.homestateConfig}.json`;
        }
        return this.http.get(url).pipe(
            mergeMap((res: { status: string, data: any }) => of(this.mapDefault(res))),
            catchError((error: any) => this.handleError(error))
        );
    }


    private isMeterConnected(): boolean {
        const connected = +localStorage.getItem(StorageAttributes.IS_METER_CONNECTED);
        if (connected === 1) {
            return true;
        } else if (connected === null || connected === undefined) {
            return true;
        }
        return false;
    }

    /**
     * Fetch the power thresholds configuration.
     * @returns {Observable<any>} The response containing the configuration.
     */
    fetchPowerThresholds(): Observable<any> {
        const url = this.API_BASE_URL + constants.api.routes.powerThresholds;

        return this.http.get(url).pipe(
            map((response: any) => response),
            catchError((error: HttpErrorResponse) => this.handleError(error))
        );
    }

    /**
     * Update the power thresholds configuration.
     * @param payload The configuration data to be updated.
     * @returns {Observable<any>} The response from the update operation.
     */
    updatePowerThresholds(payload: any): Observable<any> {
        const url = this.API_BASE_URL + constants.api.routes.powerThresholds;

        return this.http.put(url, payload).pipe(
            map((response: any) => response),
            catchError((error: HttpErrorResponse) => this.handleError(error))
        );
    }
}

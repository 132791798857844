import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {BehaviorSubject, forkJoin, Observable, of, Subscription, timer} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {constants} from '../shared/constants/constants';
import {BaseService} from './base-service';
import * as moment from 'moment';
import {months} from 'moment';
import {ApplicationService} from './application.service';
import {UserService} from './user.service';
import {MetaResponse} from '../shared/interfaces/meta-response';

@Injectable({
    providedIn: 'root'
})
export class InstantaneousService extends BaseService {
    onInstantaneousUpdate = new BehaviorSubject<any>(-1);
    onConsumptionAlertDataUpdate = new BehaviorSubject<MetaResponse>(null);

    private timerSub: Subscription = null;
    private consumptionAlertTimerSub: Subscription = null;

    private updateRate = 10000;
    private latestConsumptionAlertData = null;

    constructor(protected http: HttpClient,
                protected auth: ApiService,
                protected user: UserService,
                private application: ApplicationService) {
        super(http, auth, user);
    }

    destroy(): void {
        super.destroy();
        if (this.timerSub) {
            this.timerSub.unsubscribe();
            delete this.timerSub;
        }
        if (this.consumptionAlertTimerSub) {
            this.consumptionAlertTimerSub.unsubscribe();
            delete this.consumptionAlertTimerSub;
        }

    }


    /**
     * Start cyclic consumption alert value update
     * @param maxOffset
     */
    startConsumptionAlertValueLiveUpdate(maxOffset): void {
        if (this.consumptionAlertTimerSub) {
            return;
        }
        const now = moment();
        const to = moment(now)
            .add(5, 'minutes')
            .toDate().toUTCString().toString();
        const from = moment(now)
            .subtract(maxOffset + 1, 'days')
            .toDate().toUTCString().toString();

        this.consumptionAlertTimerSub = timer(0, this.updateRate).pipe(
            mergeMap(() => this.requestDataForTimeframe(from, to)),
        ).subscribe({
            next: (res: MetaResponse) => {
                this.onConsumptionAlertDataUpdate.next(res);
            }
        });
    }


    /**
     * Oh boy can I delete this please?
     * @param start
     * @param end
     * @param ignoreError
     */
    getPhase(start: Date, end: Date, ignoreError = false): Observable<any> {
        const s = moment(start).format('YYYY-MM-DD');
        const e = moment(end).format('YYYY-MM-DD');
        const suffix = `/${s}/${e}`;

        let url = this.API_BASE_URL + constants.api.routes.phase + suffix;
        if (start === null && end === null) {
            url = `assets/data/demo/${constants.demo.files.instantaneousPhases}.json`;
        }
        return this.http.get(url).pipe(
            map((res: { status: string, data: any }) => {
                if (!this.responseValid(res)) {
                    console.log('Response invalid', res);
                    return of(null);

                }
                const r = res['data'];
                if ('L1' in r && 'L2' in r && 'L3' in r) {
                    return r;
                }
                return null;
            }),
            catchError((error: HttpErrorResponse) => {
                if (ignoreError) {
                    return of(
                        {
                            total: {power_min: 0, power_max: 0, power_avg: 0},
                            L1: {power_min: 0, power_max: 0, power_avg: 0},
                            L2: {power_min: 0, power_max: 0, power_avg: 0},
                            L3: {power_min: 0, power_max: 0, power_avg: 0}
                        }
                    );
                }
                return this.handleError(error);
            })
        );
    }


    /**
     * Request phases for month
     * @param start
     * @param end
     */
    getPhasesForMonths(start, end): Observable<any> {
        const dates = this.generatesMonthlyRange(start, end);

        if (this.application.isDemoMode()) {
            return this.http.get('assets/data/demo/instantaneous_phases_history.json').pipe(
                map((res: any[]) => {
                    res = res.map((el) => el.data);
                    return this.alignPhaseData(res, dates);
                })
            );
        }

        const calls: Observable<any>[] = dates.map((el) => {
            return this.getPhase(el.start, el.end, true);
        });
        return forkJoin(calls).pipe(
            map(res => {
                return this.alignPhaseData(res, dates);
            })
        );
    }


    /**
     * Request instantaneous data for a specific timeframe
     * @param start
     * @param end
     */
    // this
    private requestDataForTimeframe(start: string, end: string): Observable<MetaResponse> {
        let url = this.API_BASE_URL + constants.api.routes.instantaneousPower +
            `/${start}/${end}/900/max`;
        if (this.application.isDemoMode()) {
            url = `assets/data/demo/${constants.demo.files.instantaneousPower}.json`;
        }
        return this.http.get(url)
            .pipe(
                mergeMap((res: { status: string, data: any }) => of(this.mapDefault(res))),
                mergeMap((mappedResponse: any) => {
                    if (!this.application.isDemoMode()) {
                        if (!mappedResponse) {
                            return this.mapToMetaResponse(
                                mappedResponse,
                                true
                            );
                        }
                        this.latestConsumptionAlertData = mappedResponse;
                        return this.mapToMetaResponse(
                            mappedResponse,
                            false
                        );
                    }
                    return this.mapToMetaResponse(
                        this.alignConsumptionAlertPowerDataForDemoMode(mappedResponse),
                        false
                    );
                }),
                catchError(error => {
                    return this.mapToMetaResponse(error, true);
                })
            );
    }


    /**
     * Send Instantaneous base request
     */
    private requestInstantaneous(): Observable<any> {
        let url = this.API_BASE_URL + constants.api.routes.instantaneous;
        if (this.application.isDemoMode()) {
            url = `assets/data/demo/${constants.demo.files.instantaneous}.json`;
        }
        return this.http.get( url ).pipe(
            map((res: { status: string, data: any }) => this.mapDefault(res)),
            catchError((error: any) => this.handleError(error))
        );
    }


    /**
     * Mapping function for demo mode
     * @param res
     */
    private alignConsumptionAlertPowerDataForDemoMode(res): any {
        const now = moment();
        const nearest = Math.floor(now.minutes() / 15) * 15;
        const tsStart = moment(now).minutes(nearest).second(0).millisecond(0);
        const reversed = res.results.slice().reverse();
        reversed.forEach((element, idx) => {
            const newTimestamp = moment(tsStart).subtract(idx * 15, 'minutes');
            element.timestamp = newTimestamp.toDate();
        });
        const results = reversed.slice().reverse();
        return {results};
    }


    /**
     * Phase checker stuff
     * @param start
     * @param end
     */
    private generatesMonthlyRange(start, end): any {
        const dates = [{start: start.toDate(), end: end.toDate()}];

        const num_history_months = moment(end).diff(moment('01-01-2018'), 'months', true);
        for (let i = 1; i < Math.ceil(num_history_months); ++i) {
            const month = moment(start).subtract(i, 'month');
            const month_start = moment(month).startOf('month');
            const month_end = moment(month).endOf('month');
            dates.push({start: month_start.toDate(), end: month_end.toDate()});
        }

        return dates.reverse();
    }


    /**
     * Phase Checker stuff part deux
     * @param res
     * @param dates
     */
    private alignPhaseData(res: any, dates): any {
        const aligned = {phase1: [], phase2: [], phase3: []};
        const original = {phase1: [], phase2: [], phase3: []};

        res.forEach((el, idx) => {
            const date = dates[idx].start.getTime();

            if (el === null) {
                original.phase1.push([date, 0]);
                original.phase2.push([date, 0]);
                original.phase3.push([date, 0]);

                aligned.phase1.push([date, 0]);
                aligned.phase2.push([date, 0]);
                aligned.phase3.push([date, 0]);

            } else {
                const p1 = el.L1;
                const p2 = el.L2;
                const p3 = el.L3;

                original.phase1.push([date, p1.power_max]);
                original.phase2.push([date, p2.power_max]);
                original.phase3.push([date, p3.power_max]);

                aligned.phase1.push([date, p1.power_max]);
                aligned.phase2.push([date, p2.power_max + p1.power_max]);
                aligned.phase3.push([date, p3.power_max + p2.power_max + p1.power_max]);
            }
        });
        // console.log({aligned, original});
        return {aligned, original};
    }

}

<div class="overlay">
    <div class="detail-overlay">
        <div class="detail-view">
            <section class="detail-head">
                <header class="detail-header">
                    <h1 class="detail-heading">{{ 'screens.dashboard.realTimeAlert.title' | translate }}</h1>

                    <button angulartics2On="click" angularticsCategory="Detailansichten"
                            angularticsAction="detail_info_text" angularticsLabel="Verbrauchs-Alarm"
                            (click)="infoVisible = !infoVisible">
                        <i class="info-sm dark-grey"></i>
                    </button>

                    <button (click)="close()">
                        <i class="close-sm dark-grey"></i>
                    </button>
                </header>
            </section>

            <div class="tab-bar">
                <div [class.active]="currentMode === alarmViewMode.OVERVIEW"
                     (click)="setMode(alarmViewMode.OVERVIEW)">
                    {{ 'screens.dashboard.realTimeAlert.overviewTab' | translate }}
                </div>
                <div [class.active]="currentMode === alarmViewMode.MANAGE"
                     (click)="setMode(alarmViewMode.MANAGE)">
                    {{ 'screens.dashboard.realTimeAlert.manageTab' | translate }}
                </div>
                <div class="grow-space"></div>
            </div>

            <div class="detail-content" *ngIf="currentMode === alarmViewMode.OVERVIEW">
                <section class="detail-content-graph">
                    <ng-container *ngIf="!showEmptyState; else emptyState">
                        <app-real-time-alert-chart
                            [isDetail]="true"
                            (chartLoaded)="onChartLoaded($event)">
                        </app-real-time-alert-chart>

                        <div class="diagram-controls">
                            <div class="left">
                                <button class="eon-icon-button small"
                                        [disabled]="currentDataOffset === maxDataOffset"
                                        (click)="stepBack()">
                                    <i class="chevron-left-s white"></i>
                                </button>
                            </div>
                            <div class="right">
                                <button class="eon-icon-button small"
                                        [disabled]="currentDataOffset === 0"
                                        (click)="stepForward()">
                                    <i class="chevron-right-s white"></i>
                                </button>
                                <div class="m-r-m"></div>
                                <button class="eon-button-new small"
                                        [disabled]="currentDataOffset === 0"
                                        (click)="resetHistory()">
                                    {{ 'common.now' | translate }}
                                </button>
                            </div>
                        </div>
                    </ng-container>
                </section>
                <div class="detail-sidebar">
                    <div class="meter" *ngIf="alarmsData">
                        <div class="details">
                            <img [src]="determineMeterValue()" height="117" width="165" alt="">
                            <div class="meter-info">
                                <span class="text-and-icon" *ngIf="firstBelowPowerValue">
                                    <img alt="" [ngSrc]="belowIcon" width="16"
                                         height="16"> {{firstBelowPowerValue}}
                                </span>
                                <span class="text-and-icon" *ngIf="firstAbovePowerValue">
                                    <img alt="" [ngSrc]="aboveIcon"
                                         width="16" height="16"> {{firstAbovePowerValue}}
                                </span>
                            </div>
                        </div>
                        <p [style.width.px]="126">
                            <b>{{ powerValue.power }} Watt</b>
                            unter dem nächstgelegenen Alarm
                        </p>
                    </div>
                    <div class="meter" *ngIf="!alarmsData">
                        <div class="details">
                            <img ngSrc="assets/img/graphics/real-time/no-alarms.png" height="117" width="165" alt="">
                        </div>
                    </div>
                    <div class="no-alarms-box" *ngIf="!alarmsData">
                        <p>
                            Du hast keinen Alarm eingestellt. Gib Gummi, erstell dir maximal 4 Alarme um richtig
                            abzuklingeln.
                        </p>
                    </div>
                    <div class="alarms-wrapper">
                        <div class="alarms-list" *ngFor="let alarm of alarmsData">
                            <div class="info">
                                <div class="title">
                                    <img [ngSrc]="getIconForAlarmType(alarm.type, true)" width="23"
                                         height="23" alt=""/>
                                    <h4><b>{{ alarm.name }}</b></h4>
                                </div>
                                <button class="toggle-button"
                                        [class.activated]="alarm.enabled"
                                        [class.deactivated]="!alarm.enabled"
                                        (click)="toggleAlarms(alarm.enabled, alarm.name)"></button>
                            </div>
                            <div class="details">
                                <p>{{ alarm.threshold_value }} Watt</p>
                                <img [src]="getIconForAlarmType(alarm.type)"
                                     alt="" style="height: 24px; width: auto;"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="manage-button">
                        <button class="eon-button-new turquoise" [disabled]="!chartInitialized">
                            Alarme verwalten
                        </button>
                    </div>
                </div>
            </div>
            <div class="detail-content" *ngIf="currentMode === alarmViewMode.MANAGE">
                <section class="add-alarms-content">
                    <ng-container *ngIf="!showEmptyState; else emptyState">
                        <div class="alarm-form" *ngFor="let alarm of alarmsData">
                            <div class="alarm-title">
                                <div class="name-and-delete">
                                    <h4>{{ alarm.name }}</h4>
                                    <button class="delete-button">
                                        <img src="assets/img/graphics/real-time/trash-can.png" alt="delete" />
                                    </button>
                                </div>
                                <button class="toggle-button" [class.activated]="alarm.enabled" (click)="toggleAlarms(alarm.enabled, alarm.name)"></button>
                            </div>
                            <p class="input-label">Benennen Sie diesen Alarm: </p>
                            <fieldset class="eon-input">
                                <input id="urlaub" type="text" class="eon-input"
                                       [value]="alarm.name"
                                [style.top.px]="14">
                            </fieldset>
                            <p class="input-label">Benennen Sie diesen Alarm:</p>
                            <div class="eon-dropdown" [style.height.px]="56" [style.border]="'2px solid #8F9192'">
                                <label for="hc-timeframe">ensar</label>
                                <select id="hc-timeframe">
                                    <option>
                                        ensar
                                    </option>
                                    <option>
                                        ensar
                                    </option>
                                    <option>
                                        ensar
                                    </option>
                                </select>
                                <span></span>
                            </div>
                            <p class="input-label">Verbrauchswert:</p>
                            <fieldset class="eon-input">
                                <input id="login-email" type="text" class="eon-input" [style.top.px]="14">
                            </fieldset>
                        </div>
                    </ng-container>
                </section>
                <div class="detail-sidebar">
                    <div class="add-alarm-section">
                        <button class="eon-button-new turquoise">
                            Alarm hinzufügen
                        </button>
                        <p>Alarme angelegt: {{ alarmsData?.length }}/4</p>
                    </div>
                    <div class="manage-button">
                        <button class="eon-button-new turquoise">
                            Änderungen speichern
                        </button>
                    </div>
                </div>
            </div>
            <ng-template #emptyState>
                <div class="animation-wrapper">
                    <div class="animation-container"
                         lottie [options]="lottieConfig">
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>

<div class="inflate container">
    <ng-container *ngIf="combinedClusterRankData$ | async as combinedData">

        <ng-container *ngIf="combinedData.householdRankData.viewState === ViewState.LOADING">
            <div class="inflate center-contents">
                {{ LABELS.stateLoading }}
            </div>
        </ng-container>

        <ng-container *ngIf="combinedData.householdRankData.viewState === ViewState.ERROR">
            <div class="inflate empty-state-container">
                <div class="empty-state-wrapper">
                    <div class="empty-state-img"></div>
                    <div class="empty-state-label">
                        {{ LABELS.stateError }}
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="combinedData.householdRankData.viewState === ViewState.NO_DATA">
            <div class="inflate empty-state-container">
                <div class="empty-state-wrapper">
                    <div class="no-data-img"></div>
                    <div class="empty-state-label">
                        {{LABELS.noDataState}}
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="combinedData.householdRankData.viewState === ViewState.EMPTY">
            <div class="inflate empty-state-container">
                <div class="empty-state-wrapper">
                    <div class="empty-state-img"></div>
                    <div class="empty-state-label">
                        {{ LABELS.stateEmptyDetail }}
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="combinedData.householdRankData.viewState === ViewState.SUCCESS">
            <div class="ranking-list">

                <h2>{{ TEXTS.rankingTitle }}</h2>
                <app-household-comparison-rank-row
                    *ngFor="let row of combinedData.householdRankData.rowConfigs"
                    [config]="row">
                </app-household-comparison-rank-row>

                <div class="rank-row">
                    <div class="label">
                        {{ TEXTS.ranking.messages.meanConsumption }}
                        {{ combinedData.combinedClusterRankData.averageConsumption | number:'1.0-0':'de-DE' }}
                        {{ LABELS.unitKwh }}
                        .
                    </div>
                </div>

            </div>
        </ng-container>

    </ng-container>
</div>

<div class="tile-container">
    <div class="tile" angulartics2On="click" angularticsCategory="Screens" angularticsAction="screen_view"
         angularticsLabel="screen: Verbrauchs-Alarm; previous_screen: Übersicht"
         (click)="onTileClicked()">
        <div class="tile-inner">
            <h3 class="tile-heading">{{ 'screens.dashboard.realTimeAlert.title' | translate }}</h3>
            <div class="tile-content col-centered">
                <div class="center-content">
                    <ng-container *ngIf="!showEmptyState; else emptyState">
                        <div class="tile-chart-abs">
                            <app-real-time-alert-chart
                                #realTimeAlertChartComponent
                                (chartLoaded)="onChartLoaded($event)">
                            </app-real-time-alert-chart>
                        </div>
                    </ng-container>
                    <ng-template #emptyState>
                        <div class="animation-container"
                             lottie [options]="lottieConfig">
                        </div>
                    </ng-template>
<!--                    <ng-template #emptyState>-->
<!--                        <ng-container>-->
<!--                            <div class="inflate empty-state-container">-->
<!--                                <div class="empty-state-wrapper">-->
<!--                                    <div class="empty-state-img"></div>-->
<!--                                    <div class="empty-state-label">-->
<!--                                        Jetzt persönlichen Alarm einrichten-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </ng-container>-->
<!--                    </ng-template>-->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="view-container inflate">
    <ng-container *ngIf="householdComparisonData$ | async as householdComparisonData">

        <ng-container *ngIf="householdComparisonData.viewState === ViewState.LOADING">
            <div class="inflate center-contents">
                {{ LABELS.stateLoading }}
            </div>
        </ng-container>

        <ng-container *ngIf="householdComparisonData.viewState === ViewState.EMPTY">
            <div class="inflate empty-state-container">
                <div class="empty-state-wrapper">
                    <div class="empty-state-img"></div>
                    <div class="empty-state-label">
                        {{ LABELS.stateEmptyDetail }}
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="householdComparisonData.viewState === ViewState.NO_DATA">
            <div class="inflate empty-state-container">
                <div class="empty-state-wrapper">
                    <div class="no-data-img"></div>
                    <div class="empty-state-label">
                        {{LABELS.noDataState}}
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="householdComparisonData.viewState === ViewState.ERROR">
            <div class="inflate center-contents">
                {{ LABELS.stateError }}
            </div>
        </ng-container>

        <ng-container *ngIf="householdComparisonData.viewState === ViewState.SUCCESS">
            <div class="tile-chart-abs">
                <app-household-comparison-chart
                    [diagramData]="householdComparisonData.diagramData"
                    [isDetailChart]="true">
                </app-household-comparison-chart>
            </div>
        </ng-container>

    </ng-container>
</div>
